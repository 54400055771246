import React from "react";
import CommandLink from '../commandLink/CommandLink'
import MenuItem from '../menuItem/MenuItem'
import './appWelcomeCss.css' ;
import formuleLogo from '../../data/appData/appImages/carteCatalogue/230px/formule.png'
import pizzaTLogo from '../../data/appData/appImages/carteCatalogue/230px/pizza_t.png'
import pizzaCLogo from '../../data/appData/appImages/carteCatalogue/230px/pizza_cf.png'
import assietteLogo from '../../data/appData/appImages/carteCatalogue/230px/assiette.png'
import tacosLogo from '../../data/appData/appImages/carteCatalogue/230px/tacos2.png'
import sandwichLogo from '../../data/appData/appImages/carteCatalogue/230px/sandwich.png'
import texMexLogo from '../../data/appData/appImages/carteCatalogue/230px/texMex.png'
import saladeLogo from '../../data/appData/appImages/carteCatalogue/230px/salade.png'
import dessertLogo from '../../data/appData/appImages/carteCatalogue/230px/dessert.png'
import boissonLogo from '../../data/appData/appImages/carteCatalogue/230px/boisson.png'
import enfantLogo from '../../data/appData/appImages/carteCatalogue/230px/enfant.png'
import asiatiqueLogo from '../../data/appData/appImages/carteCatalogue/230px/asiatique.png'


function Content(){

    const CATEGORY_URL = "https://www.walima-eat.com/#/etablissements/fr/51100/2/site/"
    const menuItemTable =[
        [formuleLogo, CATEGORY_URL + "category1", "NOS FORMULES"],
        [asiatiqueLogo, CATEGORY_URL + "category14","NOS ASIATIQUES"],
        [pizzaTLogo, CATEGORY_URL + "category2", "NOS PIZZAS T"],
        [pizzaCLogo, CATEGORY_URL + "category3", "NOS PIZZAS C F"],
        [assietteLogo, CATEGORY_URL + "category4", "NOS PLATS"],
        [tacosLogo, CATEGORY_URL + "category5", "NOS TACOS"],
        [sandwichLogo, CATEGORY_URL + "category6", "NOS SANDWICHS"],
        [texMexLogo, CATEGORY_URL + "category9", "NOS TEXMEX"],
        [enfantLogo, CATEGORY_URL + "category11","NOS MENUS ENFANT"],
        [saladeLogo, CATEGORY_URL + "category10","NOS SALADES"],
        [dessertLogo, CATEGORY_URL + "category12","NOS DESSERTS"],
        [boissonLogo, CATEGORY_URL + "category13","NOS BOISSONS"],
        
    ];
    return(

        <div className='content1Div'>
            <CommandLink></CommandLink>

            <div className="centePerCentWidthAndFloatContainer">
                <div className='rowContainer rowContainerResp'>
                    
                    <div>
                        {menuItemTable.map((item, index) => {
                                return (
                                    <MenuItem menuItemLogo={item[0]}  menuItemLink={item[1]} itemTitle={item[2]} key={index}></MenuItem>
                                );

                            })}
                    </div>
                
                </div>
            </div>
            

        </div>

    );
}

export default Content